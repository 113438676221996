import { Spin, notification } from "antd";
import { RouteComponentProps } from "@reach/router";
import idx from "idx";
import { UploadFile } from "antd/lib/upload/interface";
import { navigate } from "gatsby";
import { useBlogPostById } from "./_getBlogPostById";
import { useUpdateBlogPostMutaiton } from "./_udpateBlogPost";
import React, { FC, useCallback } from "react";
import Authorize from "components/authorize";
import MainLayout from "components/layouts/main";
import BlogPostForm from "components/blogPostForm";
import withRouter from "components/withRouter";
import routes, { BlogPostEditParams } from "routes";
import { useErrorHandler } from "utils";
import { UpdateBlogPostDto } from "types/graphql-global-types";

type Props = RouteComponentProps<BlogPostEditParams>;

const BlogPostEdit: FC<Props> = ({ id = "" }) => {
  const {
    initialValues,
    loading: blogPostLoading,
    error: blogPostError,
  } = useBlogPostById({
    fetchPolicy: "cache-and-network",
    variables: { id },
  });

  useErrorHandler(blogPostError);

  const [
    updateBlogPost,
    { loading: updateBlogPostLoading, error: updateBlogPostError },
  ] = useUpdateBlogPostMutaiton({ refetchQueries: ["GetBlogPostList"] });

  useErrorHandler(updateBlogPostError);

  const gotoBlogposts = useCallback(() => navigate(routes.blogPosts), []);

  const handleSubmit = useCallback(
    async (values: UpdateBlogPostDto) => {
      const uploadFile: UploadFile = values.image;
      const image = idx(uploadFile, x => x.originFileObj) || undefined;
      const input = {
        id,
        ...values,
        image,
      };

      const { errors, data } = await updateBlogPost({ variables: { input } });

      if (!errors) {
        notification.success({
          message: "Успех!",
          description: (
            <>
              Новость{" "}
              <strong>{idx(data, x => x.srv.blogPost.update.title)}</strong>{" "}
              была успешно обновлена
            </>
          ),
        });

        gotoBlogposts();
      }
    },
    [gotoBlogposts, id, updateBlogPost]
  );

  return (
    <Authorize>
      <MainLayout title="Редактирование новости" onBack={gotoBlogposts}>
        <Spin spinning={blogPostLoading || updateBlogPostLoading}>
          <BlogPostForm
            initialValues={initialValues}
            onSubmit={handleSubmit as any}
            onCancel={gotoBlogposts}
          />
        </Spin>
      </MainLayout>
    </Authorize>
  );
};

export default withRouter({ path: routes.blogPostEdit })(BlogPostEdit);
