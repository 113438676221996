import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import UPDATE_BLOG_POST_MUTATION from "./updateBlogPost.graphql";
import {
  UpdateBlogPost,
  UpdateBlogPostVariables,
} from "./types/UpdateBlogPost";

export function useUpdateBlogPostMutaiton(
  options?: MutationHookOptions<UpdateBlogPost, UpdateBlogPostVariables>
) {
  return useMutation(UPDATE_BLOG_POST_MUTATION, options);
}
