import { useQuery, QueryHookOptions } from "@apollo/react-hooks";
import idx from "idx";
import { UploadFile } from "antd/lib/upload/interface";
import GET_BLOG_POST_BY_ID from "./getBlogPostById.graphql";
import {
  GetBlogPostById,
  GetBlogPostByIdVariables,
} from "./types/GetBlogPostById";
import { useMemo } from "react";

export function useBlogPostById(
  options?: QueryHookOptions<GetBlogPostById, GetBlogPostByIdVariables>
) {
  const { data, ...rest } = useQuery(GET_BLOG_POST_BY_ID, options);

  const blogPost = useMemo(() => idx(data, x => x.blogPost) || undefined, [
    data,
  ]);

  const initialValues = useMemo(
    () => ({
      ...blogPost,
      image: {
        uid: idx(blogPost, x => x.image.id),
        url: idx(blogPost, x => x.image.url),
      } as UploadFile,
    }),
    [blogPost]
  );

  return { data, blogPost, initialValues, ...rest };
}
